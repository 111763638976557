import React, { useRef, useState } from 'react';
import { createGoogleMeetSpace } from '@apis/api/chat';
import { useGoogleLogin } from '@react-oauth/google';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import { useUserInfoQuery } from '@apis/query/user';
import IconContainer from '@components/common/IconContainer';
import BasicMenu, {
  BasicMenuItemContent,
} from '@components/common/list/BasicMenu';
import { ChatMessageType } from '@asset/enum/chatEnum';
import { UserType } from '@asset/enum/UserEnum';
import * as S from '@styles/chat.style';
import { AddCircleFillIcon } from '@asset/svg/AddCircleIcon';
import ImageIcon from '@asset/svg/ImageIcon';
import VideoIcon from '@asset/svg/VideoIcon';
import ScheduleIcon from '@asset/svg/ScheduleIcon';
import { getUsernameFromNickname, uploadChatFiles } from '@asset/function/chat';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { usersGoogleAuthCallback } from '@apis/api/auth';
import { useChatStore } from '@store/chat';
import _ from 'lodash';
import CloseIcon from '@asset/svg/CloseIcon';
import GroupChannelFooterInput from './GroupChannelFooterInput';

const GroupChannelFooter = ({
  channelInputRef,
  toggleScheduledMessageSectionVisible,
}: {
  channelInputRef: React.RefObject<HTMLTextAreaElement>;
  toggleScheduledMessageSectionVisible: (isOpen: boolean) => void;
}) => {
  const { data: user } = useUserInfoQuery();
  const { clickedMessageToReply, setClickedMessageToReply } = useChatStore();
  const {
    sendUserMessage,
    sendFileMessage,
    sendMultipleFilesMessage,
    currentChannel,
    scrollToBottom,
  } = useGroupChannelContext();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [isOpenMessageOptionMenu, setIsOpenMessageOptionMenu] = useState(false);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      const fileUrls = await uploadChatFiles(files, () =>
        toast.error('일부 사진 전송에 실패했습니다.'),
      );

      if (files.length === 1) {
        await sendFileMessage({ fileUrl: fileUrls[0].url });
      } else {
        await sendMultipleFilesMessage({
          fileInfoList: fileUrls.map((file) => ({
            fileUrl: file.url,
          })),
        });
      }
    }
    setIsOpenMessageOptionMenu(false);
    setTimeout(() => {
      scrollToBottom(true);
    }, 100);
  };

  const sendGoogleMeetMessage = async (googleMeetUri: string) => {
    await sendUserMessage({
      message: googleMeetUri,
      customType: ChatMessageType.GOOGLE_MEET,
    });
  };

  const createGoogleMeetSpaceAfterGoogleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/meetings.space.created',
    onSuccess: async ({ code, scope }) => {
      await usersGoogleAuthCallback(code, scope.split(' '));
      const { success, data } = await createGoogleMeetSpace();
      if (success) {
        sendGoogleMeetMessage(data.uri);
      }
      return null;
    },
  });

  const { isFetching: isFetchingGoogleMeet, refetch: createGoogleMeet } =
    useQuery(
      ['CREATE_GOOGLE_MEET'],
      async () => {
        const { success, data } = await createGoogleMeetSpace({
          handleError: (status, data) => {
            if (status === 403 && data.code === 'GOOGLE_OAUTH_TOKEN_REQUIRED') {
              createGoogleMeetSpaceAfterGoogleLogin();
              return true;
            }
            return false;
          },
        });
        if (success) {
          await sendGoogleMeetMessage(data.uri);
        }
        return null;
      },
      {
        enabled: false,
      },
    );

  const messageOptionMenuItems = [
    {
      id: ChatMessageType.FILE,
      content: (
        <>
          <BasicMenuItemContent
            icon={<ImageIcon />}
            text={'이미지 첨부'}
          ></BasicMenuItemContent>
          <input
            type="file"
            multiple
            id="chat-file-message-input"
            accept=".svg, .jpg, .png, .jpeg"
            className="hidden"
            ref={fileInputRef}
            onChange={handleFileChange}
          />
        </>
      ),
    },
  ];

  if (user?.type === UserType.TEACHER) {
    messageOptionMenuItems.push({
      id: ChatMessageType.GOOGLE_MEET,
      content: (
        <BasicMenuItemContent
          icon={<VideoIcon />}
          text={'화상 회의 시작'}
          isLoading={isFetchingGoogleMeet}
        ></BasicMenuItemContent>
      ),
    });
    messageOptionMenuItems.push({
      id: ChatMessageType.SCHEDULED,
      content: (
        <BasicMenuItemContent
          icon={<ScheduleIcon color="var(--gray-10)" />}
          text={'메시지 예약'}
        ></BasicMenuItemContent>
      ),
    });
  }
  const replyString = clickedMessageToReply
    ? user?.type === UserType.STUDENT
      ? '선생님에게 답장'
      : `${getUsernameFromNickname(
          clickedMessageToReply.sender.nickname,
        )}에게 답장`
    : '';
  const typingUsers = (currentChannel?.getTypingUsers() ?? []).map((user) =>
    getUsernameFromNickname(user.nickname),
  );
  const typingString = user
    ? typingUsers.length > 0
      ? user.type === UserType.STUDENT
        ? '선생님 입력 중...'
        : typingUsers.join(', ') + ' 입력 중...'
      : ''
    : '';

  return (
    <>
      <S.ChannelFooter>
        {typingUsers.length > 0 && (
          <S.TypingIndicator>{typingString}</S.TypingIndicator>
        )}
        {!_.isNil(clickedMessageToReply) && (
          <S.ReplyingIndicator>
            <div className="header">
              <div className="counterpart">{replyString}</div>
              <button onClick={() => setClickedMessageToReply(null)}>
                <CloseIcon color="var(--gray-50)" width="16" height="16" />
              </button>
            </div>
            <div className="reply-message-content">
              {clickedMessageToReply.isFileMessage() ||
              clickedMessageToReply.isMultipleFilesMessage()
                ? '사진'
                : clickedMessageToReply.message}
            </div>
          </S.ReplyingIndicator>
        )}
        <S.MessageInputContainer
          replyString={replyString}
          parentMessageType={
            clickedMessageToReply
              ? clickedMessageToReply.isFileMessage() ||
                clickedMessageToReply.isMultipleFilesMessage()
                ? ChatMessageType.FILE
                : clickedMessageToReply.messageType
              : null
          }
        >
          <IconContainer
            icon={
              <AddCircleFillIcon
                width="1.5rem"
                height="1.5rem"
                color="var(--gray-40)"
              />
            }
            size={'M'}
            onClick={() => setIsOpenMessageOptionMenu(!isOpenMessageOptionMenu)}
            onTouchEnd={(e) => e.stopPropagation()}
          ></IconContainer>
          {isOpenMessageOptionMenu && (
            <BasicMenu
              items={messageOptionMenuItems}
              customStyles={{
                top: '70%',
                left: '0',
                transform: 'translateY(-120%)',
              }}
              isLoading={isFetchingGoogleMeet}
              onClickAway={() => setIsOpenMessageOptionMenu(false)}
              onClickItem={async (item) => {
                if (item.id === ChatMessageType.SCHEDULED) {
                  toggleScheduledMessageSectionVisible(true);
                } else if (item.id === ChatMessageType.FILE) {
                  if (!isFetchingGoogleMeet) {
                    fileInputRef.current?.click();
                  }
                } else if (item.id === ChatMessageType.GOOGLE_MEET) {
                  await createGoogleMeet();
                  setIsOpenMessageOptionMenu(false);
                }
              }}
            ></BasicMenu>
          )}
          <GroupChannelFooterInput channelInputRef={channelInputRef} />
        </S.MessageInputContainer>
      </S.ChannelFooter>
    </>
  );
};

export default GroupChannelFooter;
