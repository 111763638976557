import _ from 'lodash';
import {
  getGroupChannelsUrlContainsStudentName,
  getSendbirdUserInfo,
} from '@apis/api/chat';
import { useChatStore } from '@store/chat';
import { useUserInfoQuery } from '@apis/query/user';
import { useQuery } from 'react-query';
import {
  GroupChannel as GroupChannelType,
  HiddenChannelFilter,
} from '@sendbird/chat/groupChannel';
import { GroupChannelListProvider } from '@sendbird/uikit-react/GroupChannelList/context';
import { useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { UserType } from '@asset/enum/UserEnum';
import CustomGroupChannelList from './CustomGroupChannelList';
import { DraggableCore } from 'react-draggable';
import { GroupChannelCustomType } from '@asset/type/chat';
import CustomGroupChatRoom from './GroupChannel/CustomGroupChannel';
import * as S from '@styles/chat.style';
import { Rnd } from 'react-rnd';
import { useWindowSize } from '@public/hooks/useWindowSize';
import { useKeyboardVisible } from 'hook/keyboard';
import CustomGroupChannelListSkeleton from './CustomGroupChannelListSkeleton';
import { customStringSet } from 'constants/chat';

const SendbirdProvider = dynamic(
  () => import('@sendbird/uikit-react/SendbirdProvider'),
  {
    ssr: false,
  },
);

export const RESIZABLE_CHAT_ELEMENT_CLASSNAME = 'resizable-chat-element';

function SendbirdWrapper() {
  const SEND_BIRD_APP_ID = process.env.NEXT_PUBLIC_SEND_BIRD_APP_ID;
  const { data: user } = useUserInfoQuery();
  const {
    chatRoomRef: storeChatRoomRef,
    isOpen,
    selectedChannelUrl,
    clickedChatNotification,
    setChatRoomRef,
    setIsOpen,
    setSelectedChannelUrl,
  } = useChatStore();
  const chatRoomRef = useRef(null);
  const { width: windowWidth } = useWindowSize();
  const isTablet = 800 < windowWidth && windowWidth < 1350;

  const [selectedChannelCustomType, setSelectedChannelCustomType] =
    useState<GroupChannelCustomType>(GroupChannelCustomType.IN_CLASS);
  const [channelUrlsIncludeSearchMember, setChannelUrlsIncludeSearchMember] =
    useState<string[]>(null);
  const [isOpenMemberSearchInput, setIsOpenMemberSearchInput] = useState(false);
  const [memberSearchInputValue, setMemberSearchInputValue] = useState(null);

  const { data: sendbirdUserInfo } = useQuery(
    ['GET_SENDBIRD_USER_INFO', user?.userId],
    () => getSendbirdUserInfo(user?.userId),
    {
      enabled: !_.isNil(user?.userId),
    },
  );
  const sendbirdUserId = sendbirdUserInfo?.userId;
  const sendbirdAccessToken = sendbirdUserInfo?.accessToken;

  useEffect(() => {
    if (sendbirdUserInfo && isOpen) {
      setSelectedChannelUrl(sendbirdUserInfo.currentClassChannelUrl);
    }
  }, [sendbirdUserInfo, isOpen]);

  const closeMemberSearchInput = () => {
    setIsOpenMemberSearchInput(null);
    setMemberSearchInputValue(null);
    setChannelUrlsIncludeSearchMember(null);
  };

  useEffect(() => {
    if (_.isNil(user)) {
      setIsOpen(false);
      setSelectedChannelUrl(null);
      setSelectedChannelCustomType(null);
      closeMemberSearchInput();
    }
  }, [user]);

  useKeyboardVisible(
    (initialInnerHeight) => {
      if (chatRoomRef && chatRoomRef.current && isOpen) {
        const keyboardHeight =
          initialInnerHeight - window.visualViewport.height;
        const newTop =
          initialInnerHeight -
          (keyboardHeight +
            chatRoomRef.current.querySelector(
              `.${RESIZABLE_CHAT_ELEMENT_CLASSNAME}`,
            ).clientHeight +
            10);
        chatRoomRef.current.style.top = `${newTop}px`;
      }
    },
    () => {
      if (chatRoomRef && chatRoomRef.current && isOpen) {
        chatRoomRef.current.style.top = `10px`;
      }
    },
  );

  const handleDragChatRoom = (e, data) => {
    if (chatRoomRef && chatRoomRef.current) {
      const resizableChatElement = chatRoomRef.current.querySelector(
        `.${RESIZABLE_CHAT_ELEMENT_CLASSNAME}`,
      );
      // window 가장 윗쪽에서 chatRoomRef까지의 거리
      const chatRoomTopInWindow = chatRoomRef.current.offsetTop;
      // window 가장 윗쪽에서 마우스까지의 거리
      const mouseTopInWindow = data.y;
      // chatRoom 가장 윗쪽에서 마우스까지의 거리
      const mouseTopInChatRoom = mouseTopInWindow - chatRoomTopInWindow;
      const movedTop = mouseTopInWindow - mouseTopInChatRoom + data.deltaY;
      const chatRoomFinalTopInWindow =
        movedTop > 0
          ? movedTop > window.innerHeight - chatRoomRef.current.clientHeight
            ? window.innerHeight - chatRoomRef.current.clientHeight
            : movedTop
          : 0;

      // window 가장 왼쪽에서 chatRoomRef까지의 거리
      const chatRoomLeftInWindow = chatRoomRef.current.offsetLeft;
      // window 가장 왼쪽에서 마우스까지의 거리
      const mouseLeftInWindow = data.x;
      // chatRoom 가장 왼쪽에서 마우스까지의 거리
      const mouseLeftInChatRoom = mouseLeftInWindow - chatRoomLeftInWindow;
      const movedLeft = mouseLeftInWindow - mouseLeftInChatRoom + data.deltaX;
      const chatRoomFinalLeftInWindow =
        movedLeft > 0
          ? movedLeft > window.innerWidth - resizableChatElement.clientWidth
            ? window.innerWidth - resizableChatElement.clientWidth
            : movedLeft
          : 0;

      storeChatRoomRef.current.style.top = `${chatRoomFinalTopInWindow}px`;
      storeChatRoomRef.current.style.left = `${chatRoomFinalLeftInWindow}px`;
    }
  };

  useEffect(() => {
    setChatRoomRef(chatRoomRef);
  }, []);

  if (!_.isNil(sendbirdUserId) && !_.isNil(sendbirdAccessToken) && isOpen) {
    return (
      <DraggableCore
        cancel=".chat-header-button, .chat-announcement"
        handle=".drag-handle"
        onDrag={handleDragChatRoom}
      >
        <S.ChatRoom ref={chatRoomRef}>
          <Rnd
            resizeHandleStyles={{
              right: { cursor: 'ew-resize' },
              bottom: { cursor: 'ns-resize' },
              bottomRight: { cursor: 'nwse-resize' },
            }}
            className={`z-[99] w-fit !flex flex-col bg-my-gray-100 rounded-xl overflow-hidden border border-solid border-my-gray-70 !transform-none ${RESIZABLE_CHAT_ELEMENT_CLASSNAME}`}
            style={{
              boxShadow: '0px 8px 24px 0px rgba(23, 32, 42, 0.16)',
            }}
            minWidth={S.chatRoomRemWidth * 16}
            maxWidth={window.innerWidth / 2}
            minHeight={(window.innerHeight / 100) * 60}
            maxHeight={window.innerHeight}
            disableDragging
            default={{
              x: null,
              y: null,
              height: S.chatRoomRemWidth * 16,
              width: null,
            }}
            enableResizing={{
              right: isTablet ? false : true,
              bottom: isTablet ? false : true,
              bottomRight: isTablet ? false : true,
            }}
          >
            <>
              <S.ChatGlobalStyle />
              <SendbirdProvider
                appId={SEND_BIRD_APP_ID}
                userId={sendbirdUserId}
                accessToken={sendbirdAccessToken}
                stringSet={customStringSet}
                colorSet={{
                  '--sendbird-light-primary-100': 'var(--blue-98)',
                  '--sendbird-light-primary-300': 'var(--blue-50)',
                  '--sendbird-light-primary-400': 'var(--blue-40)',
                  '--sendbird-light-primary-500': 'var(--blue-30)',
                  '--sendbird-light-secondary-300': 'var(--blue-50)',
                }}
                isMultipleFilesMessageEnabled
              >
                {_.isNil(selectedChannelUrl) ? (
                  user.type === UserType.TEACHER ? (
                    <section className="flex w-full h-full">
                      <GroupChannelListProvider
                        onChannelSelect={async (channel: GroupChannelType) => {
                          setSelectedChannelUrl(channel.url);
                        }}
                        onChannelCreated={() => null}
                        disableAutoSelect
                        channelListQueryParams={{
                          hiddenChannelFilter: HiddenChannelFilter.UNHIDDEN,
                          customTypesFilter: !clickedChatNotification
                            ? selectedChannelCustomType
                              ? [selectedChannelCustomType]
                              : null
                            : null,
                          channelUrlsFilter: channelUrlsIncludeSearchMember,
                        }}
                      >
                        <CustomGroupChannelList
                          sendbirdUserInfo={sendbirdUserInfo}
                          selectedChannelCustomType={selectedChannelCustomType}
                          isOpenSearchInput={isOpenMemberSearchInput}
                          memberSearchInputValue={memberSearchInputValue}
                          channelUrlsIncludeSearchMember={
                            channelUrlsIncludeSearchMember
                          }
                          onSelectChannelCustomType={
                            setSelectedChannelCustomType
                          }
                          toggleSearchInputVisible={(visible: boolean) => {
                            setIsOpenMemberSearchInput(visible);
                            if (!visible) {
                              closeMemberSearchInput();
                            }
                          }}
                          onSearch={async (value) => {
                            setMemberSearchInputValue(value);
                            const pickedChannelUrls = (
                              await getGroupChannelsUrlContainsStudentName(
                                value,
                              )
                            ).data.map((channel) => channel.channelUrl);
                            setChannelUrlsIncludeSearchMember(
                              pickedChannelUrls,
                            );
                          }}
                        />
                      </GroupChannelListProvider>
                    </section>
                  ) : (
                    <CustomGroupChannelListSkeleton />
                  )
                ) : (
                  <CustomGroupChatRoom
                    onCloseGroupChannel={() => setSelectedChannelUrl(null)}
                  />
                )}
              </SendbirdProvider>
            </>
          </Rnd>
        </S.ChatRoom>
      </DraggableCore>
    );
  } else {
    <></>;
  }
}

export default SendbirdWrapper;
